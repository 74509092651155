/* eslint @typescript-eslint/no-explicit-any: 0 */

/* eslint no-unused-vars: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

import TEXT from '@app/core/constants/text';
import { getProduct, setProduct } from '@app/core/helpers/productHelper';
import { Product } from '@app/core/types/product';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Cookies from 'universal-cookie';
import { PRODUCT_ENUM } from '@app/core/enums';
import { ApiService } from '@app/core/services/api.service';
import { useDispatch, useSelector } from 'react-redux';
import { hideNotFound, showNotFound } from '../../not_found/notfound.actions';
import { ResponseErrType } from '@app/core/types/common';
import { setIsConfirmProductRedux, setProductRedux } from '@app/shared/product/product.actions';
import { RootState } from '@app/app.reducers';

interface PropsInterface {
  closeModal: () => void;
}

// interface ProductChangeType {
//   product: Product;
//   isConfirm: boolean;
// }

const Getpoints = (props: PropsInterface) => {
  const { closeModal } = props;
  const [points, setPoints] = useState<string>('');
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const cookies = new Cookies({ path: '/LSWC',secure: true, sameSite: true, maxAge: 3600});
//   const productChange: ProductChangeType = useSelector((state: RootState) => state.productReducer);

  const onConfirm = () => {
    setLoading(true);
    // TODO: go to record
    history.push({
        pathname: '/badges',
        // search: `?typeId=''&actionType=''`,
      });
    cookies.remove('buyfrom');
    cookies.remove('itemStatus');
    cookies.remove('item');
    cookies.remove('code');
    cookies.remove('qrId');
    cookies.remove('points');
    cookies.remove('sccsId');
    closeModal();
    // const payload = {
    //   productId: productChange.product.productId,
    // };
    // await api
    //   .post(['/products'], payload)
    //   .then(res => {
    //     if ((res as ResponseErrType).errorCode) {
    //       dispatch(
    //         showNotFound({
    //           func: () => {
    //             dispatch(hideNotFound());
    //             onConfirm();
    //           },
    //         }),
    //       );
    //     } else {
    //       dispatch(setIsConfirmProductRedux(true));
    //       setProduct(productChange.product);
    //     }
    //   })
    //   .catch(() => {
    //     dispatch(
    //       showNotFound({
    //         func: () => {
    //           dispatch(hideNotFound());
    //           onConfirm();
    //         },
    //       }),
    //     );
    //   })
    //   .finally(() => {
    //     setLoading(false);
    //   });
  };

//   const checkTypeProduct = () => {
//     return setText(TEXT.PRODUCT.MODAL_CONFIRM.CONTENT);
//     const preProduct = getProduct();
//     if (preProduct?.typeProduct! === productChange.product.typeProduct) {
//       return setText(TEXT.PRODUCT.MODAL_CONFIRM.CONTENT_SAME);
//     } else if (preProduct?.typeProduct! === PRODUCT_ENUM.SESAMIN) {
//       return setText(TEXT.PRODUCT.MODAL_CONFIRM.CONTENT_SESAMIN_TO_OTHER);
//     } else {
//       return setText(TEXT.PRODUCT.MODAL_CONFIRM.CONTENT_OTHER_TO_SESAMIN);
//     }
//   };

  useEffect(() => {
    // checkTypeProduct();
    setPoints(cookies.get('points'));
  }, []);

//   useEffect(() => {
//     if (productChange.isConfirm === true) {
//       dispatch(setProductRedux({}));
//       closeModal();
//     }
//   }, [productChange]);

  return (
    <div className="pd-5 pb-8 gift">

      <span className="note txt-m mt-3 txt-left">
        {/* modal note */}
        {TEXT.LSWC.MODAL.GET}
      </span>
      <div className="txt-l">
        {/* modal title */}
        {points}{TEXT.LSWC.POINT}
      </div>
      <div className="my-4" onClick={() => onConfirm()}>
        <button className="btn btn-primary receive-btn" disabled={isLoading}>
          {isLoading ? <div className="loader mx-auto"></div> : TEXT.LSWC.MODAL.BTN_SUBMIT}
        </button>
      </div>
      {/* <div className="pt-4">
        <a className="terms" onClick={() => closeModal()}>
          {TEXT.GENERAL.CLOSE}
        </a>
      </div> */}
    </div>
  );
};

export default Getpoints;