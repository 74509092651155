/* eslint @typescript-eslint/no-explicit-any: 0 */

/* eslint prefer-promise-reject-errors: 0 */

import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { environment } from '@config/environment';
import AuthHelper from '../helpers/authHelper';
import { logError } from '../helpers/logger';

export class ApiService {
  axiosInstance: AxiosInstance;
  authHelper: AuthHelper;

  constructor(baseUrl?: string, headers?: {}) {
    this.authHelper = new AuthHelper();
    // Init axiosInstance
    this.axiosInstance = axios.create({
      baseURL: baseUrl??environment.apiBaseUrl,
      // Common header
      headers: headers??{
        'Content-Type': 'application/json',
        ...this.authHelper.defaultHeader(),
      },
    });
    this._setInterceptors();
  }

  createURL(uri: (string | object)[]) {
    /* @ts-expect-error */
    let paramsUrl;
    if (typeof uri[uri.length - 1] !== 'string') {
      paramsUrl = uri.pop();
      let url = uri.join('/');
      /* @ts-expect-error */
      Object.keys(paramsUrl).forEach(x => {
        /* @ts-expect-error */
        url = url.replace(`:${x}`, paramsUrl[x]);
      });
      return url;
    } else {
      return uri.join('/');
    }
  }

  get(uri: (string | object)[], params = {}, moreConfigs = {}) {
    return new Promise((resolve, reject) => {
      const request = this.axiosInstance.get(this.createURL(uri), { params, ...moreConfigs });
      this._handleRespond(request, resolve, reject);
    });
  }

  post(uri: (string | object)[], data = {}, moreConfigs = {}) {
    return new Promise((resolve, reject) => {
      const request = this.axiosInstance.post(this.createURL(uri), data, moreConfigs);
      this._handleRespond(request, resolve, reject);
    });
  }

  put(uri: (string | object)[], data = {}, moreConfigs = {}) {
    return new Promise((resolve, reject) => {
      const request = this.axiosInstance.put(this.createURL(uri), data, moreConfigs);
      this._handleRespond(request, resolve, reject);
    });
  }

  delete(uri: (string | object)[], moreConfigs = {}) {
    return new Promise((resolve, reject) => {
      const request = this.axiosInstance.delete(this.createURL(uri), moreConfigs);
      this._handleRespond(request, resolve, reject);
    });
  }

  multipeGets(apiRequests: any) {
    const apiReqs = apiRequests.map((v: any) => this.axiosInstance.get(v));
    return new Promise((resolve, reject) => {
      axios
        .all(apiReqs)
        /* @ts-expect-error */
        .then((resp: AxiosResponse[]) => {
          resolve(
            resp.map((v: any) => {
              if (v.data instanceof Object && 'errorCode' in v.data) {
                logError(v.data);
              }
              return v.data;
            }),
          );
        })
        .catch((err: any) => {
          this._handleMaintenanceRedirect(err, reject);
        });
    });
  }

  /* @ts-expect-error */
  private _handleRespond(request: any, resolve, reject) {
    return request
      .then((resp: AxiosResponse) => {
        if (resp.data instanceof Object && 'errorCode' in resp.data) {
          logError(resp.data);
        }
        resolve(resp.data);
      })
      .catch((err: any) => {
        this._handleMaintenanceRedirect(err, reject);
      });
  }

  private _setInterceptors() {
    this.axiosInstance.interceptors.request.use((request: AxiosRequestConfig) =>
      this.authHelper.setAuthHeader(request),
    );
    this.axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => response,
      (error: AxiosError) => this._handleError(error),
    );
  }

  private async _handleError(error: AxiosError) {
    // Detect refresh Token
    if (error.isAxiosError && error.response?.status === 401) {
      const originalRequest = error.config;
      const req = await this.authHelper.handleRefreshToken(originalRequest);
      return this.axiosInstance(req);
    }

    // Make error model before promise
    if (error.isAxiosError && error.response) {
      // Axios error
      return Promise.reject(error);
    } else {
      // Default | Network errors | CORS | ...
      return Promise.reject({});
    }
  }

  private _handleMaintenanceRedirect(err: AxiosError, reject: any) {
    if (err.response?.status === 503) {
      sessionStorage.setItem('maintenance', 'true');
      window.location.replace('/release-maintenance');
    } else {
      logError(err);
      reject(err);
    }
  }
}
