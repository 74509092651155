const TEXT = {
  GENERAL: {
    HISTORY_LINK_LABEL: '查看任務紀錄',
    CLOSE: '關閉',
    ADD: '追加',
    REMOVE: '移除',
    CLOSE_WINDOW: '關閉視窗',
    REQUIRED: '必填',
    OPTIONAL: '非必填',
    BLANK_ERROR: '本題目為必填，請選擇或填寫您的回答',
    NOT_SELECT_ERROR: '本題為單選題，請選擇您的回答',
    NOT_FILL_ERROR: '本題目為必填，請輸入您的回答',
    YES: '是',
    NO: '否',
    BACK: '回上一頁',
    VALIDATION: {
      EMAIL_INVALID: '請輸入正確的電子郵件',
      EMAIL_BLANK: '請輸入正確的帳號或電子郵件',
    },
  },
  API_ERROR: {
    submit_failed: '發生錯誤\n請稍後再試，謝謝。',
    qr_code_failed: 'QR code有誤或已被使用。'
  },
  ACTION_DETAIL: {
    TITLE_1: '1.正坐椅上，臀部靠近椅背。',
    TITLE_2: '2.雙手抱右膝向身體靠近，維持30秒，另一隻腳踩地。',
    TITLE_3: '3.右膝放下，待膝蓋肌肉緩和後，左膝重複步驟2相同動作，雙腳交替各做5次。',
    ANSWER_BTN_LABEL: '達成任務',
    BACK_BTN_LABEL: '已完成任務一覽',
  },
  KIND_DETAIL: {
    MESSAGE: '親愛的三得利會員您好✨\n為了驗證會員身分及後續獎品寄送，請填寫下列資訊，謝謝您。',
    QUESTIONNAIRE_LINK: '重新診斷',
    TYPE: 'Type:',
    ASK_EC_ACCOUNT: '曾在「三得利健康網路商店」網站購買過商品嗎？',
    LOGIN_WITH_EC: '網路會員登入驗證',
    ENTER_INFO: '電話訂購資訊驗證',
    CONTACT_MSG: '如您有任何疑問請洽免付費客服專線',
    CONTACT_PHONE: '0800-321-800',
    FORM_SUBMIT_BTN: '開始屬於您的三得利\n《天天集得利》',
    REVIEW: '請審閱​',
    TERM: '使用規範',
    AND: '及',
    POLICY: '隱私權與個資保護聲明',
    ACCEPTANCE: '已詳閱並同意上述條款​',
    PLEASE_CHECK_TERM: '請審閱條文後勾選同意',
  },
  ACTIONS_RANDOMS: {
    TITLE: '今天還有這些任務供您挑選喔！',
    REDIRECT_BTN: '點我選擇這個服務',
    EMPTY: 'Empty List',
  },
  DONE_WARNING: {
    WARNING_TEXT: '今天的任務已經完成囉！🙌🏼',
  },
  PRODUCT: {
    PLEASE_SELECT_PRODUCT: '請選擇1種您目前在食用的保健商品。',
    NOTE: '*目前尚不支援一次選擇2個或以上的保健商品。',
    LOAD_MORE_HISTORY: '他の商品',
    MODAL_CONFIRM: {
      CONFIRM_MOVE_CHANGE: '確定變更',
      TITLE: '您確定要變更商品嗎？',
      CONTENT: '商品變更後，將開始寄送變更後的商品使用提醒通知。\n\n請再次確認是否要變更商品。',
      CONTENT_SAME: '商品變更後，將開始寄送變更後的商品使用提醒通知 。\n\n請再次確認是否要變更商品。',
      CONTENT_SESAMIN_TO_OTHER: '商品變更後，將開始寄送變更後的商品使用提醒通知。\n\n請再次確認是否要變更商品。',
      CONTENT_OTHER_TO_SESAMIN:
        '商品變更後，將開始寄送變更後的商品使用提醒通知。\n\n確認變更商品後請至對話頁面完成專業檢測，完成後天天集得利將會定期寄送健康小任務與專業檢測讓您健康更上一層樓！\n\n請再次確認是否要變更商品。',
    },
    BUTTON_CANCEL: '取消',
  },
  LOGGING: {
    TITLE1: '恭喜您邁向第',
    TITLE2: '天',
    CONTENT: '快紀錄下這幾天的健康感受吧!',
    HINT: '(選項可複選)',
    CONFIRM_BTN: '點擊確認紀錄',
  },
  ENCOURAGING: {
    CONTENT:
      '恭喜完成「自我狀態檢視」紀錄里程碑!\n離健康的好眠體質又更進一步了呢👍\n今天也一起動一動,度過神清氣爽又充實的一天😀🌈',
  },
  ACTION_HISTORY: {
    RIBBON_TITLE: '第{value0}輪挑戰中',
    TAB_TITLE_1: '我的健康歷程',
    TAB_TITLE_2: '已完成任務​',
    BADGE_TITLES: {
      drinking: '保健商品',
      action: '每日任務',
      badge: '徽章',
    },
    BADGE_UNITS: {
      drinking: '天',
      action: '天',
      badge: '個',
    },
    REACH: '達成',
    START_PLAN: '開始天天集得利！',
    LOGGING_COUNT: '已經感受到{value0}個健康回饋',
    FIRST: '第',
    DAY: '天',
    DAY2: '第{value0}天',
    SECTION_TXT: '第{value0}輪✨',
    DRINKING_MEMO: '保健商品',
    ACTION_MEMO: '每日任務',
    BADGE_NAME: '得到{value0}徽章！',
    BUBBLE_TXT: '{value0}月{value1}日，開始天天集得利',
    BUBBLE_RANDOM: ['今天的狀況如何阿？一起完成任務，朝自己的理想邁進吧！'],
    LOAD_MORE_HISTORY: '看更多健康歷程',
    LOAD_MORE_COMPLETE: '查看更多已完成任務',
    MOVE_TO_FAVORITE_LABEL: '★ 點讚!設為固定任務',
    COMPLETE_EMPTY_TITLE: '還沒有已完成任務🥺',
    COMPLETE_EMPTY_DESCRIPTION: '達成每天發送的任務後，任務就會被紀錄在這邊喔！🙂',
    CHANGED_PRODUCT: '開始使用',
  },
  FAVORITE: {
    TITLE_1: '設定每周固定任務​',
    GUIDE: '按下下方「追加」按鈕，指定每周發送的任務​',
    EXAMPLE: 'Ex) 按下星期日框框中的追加按鈕，​以後每星期日都會發送{value0}任務​',
    BACK_BTN_LABEL: '回到任務列表',
    TASK_CURRENT: '目前設定的任務是:​\n{value0}',
    ADD_SUCCESS: '已將任務設為「{value0}任務」',
    DELETE_SUCCESS: '已將任務移除',
  },
  INTRODUCTION: {
    TITLE: '服務說明',
    BTN: 'アンケートへ進む',
    TEXT3: '設定每日保健食品飲用提醒及健康小任務，累積次數即可獲得認證勳章，培養健康的同時更有滿滿成就感！',
    TEXT4: '完成三得利天天集得利專屬任務，我們將會獻上任務獎勵得利金，持續飲用天天輕鬆領，期待能與您一起朝健康理想狀態邁進！',
    GIFT_NOTE: `※贈獎發送資格限加入活動對象商品之定期購且契約持續中的客戶，或是近六個月有單次購買活動對象商品的客戶。
      ※活動對象商品：
      芝麻明EX、蜂王乳＋芝麻明E、DHA＆EPA+芝麻明E、Liftage麗芙緹PG-EX、Milcolla蜜露珂娜、固力伸、TADAS 比菲禦力菌、極之青汁【大麥若葉+明日葉】、健鈣立、御瑪卡【精胺酸+鋅】、益思健、 BODYSAPO躍勁
`,
    LINK_OA_NOTE_01: `※任務獎勵發送對象限已為三得利健康網路商店會員，並完成綁定`,
    LINK_OA_NOTE_02:`LINE官方帳號【三得利健康網路商店】`,
    LINK_OA_NOTE_03:`者。`,
  },
  BADGES: {
    TITLE: '您的認證勳章​',
    GIFT_TITLE: '天天集得利贈獎發送資訊填寫',
    REGISTERED_GIFT_TITLE: '您填寫的贈獎資訊',
    GIFT_MSG: `※活動對象商品：
芝麻明EX、蜂王乳＋芝麻明E、DHA＆EPA+芝麻明E、Liftage麗芙緹PG-EX、Milcolla蜜露珂娜、固力伸、TADAS比菲禦力菌、極之青汁【大麥若葉+明日葉】、健鈣立、御瑪卡【精胺酸+鋅】、益思健、 BODYSAPO躍勁
`,
    CHOOSE: '選擇',
    PLEASE_SELECT_GIFT: '請選擇您想要的贈獎。',
    SELECT_GIFT_ERROR: '請選擇您的贈獎。',
    BTN_CHOOSE_FINISH: '填寫發送資訊',
    BTN_INFO_COMPLETED: '填寫完成！確認填寫資訊',
    BTN_FINISH: '確認',
    CONSIGNEE_NAME: '收貨人姓名:',
    CONSIGNEE_NAME_BLANK: '請輸入收貨人姓名',
    CONSIGNEE_NAME_PLACEHOLDER: '請輸入真實姓名',
    ADDRESS: '收貨人地址:',
    ADDRESS_PLACEHOLDER: '請輸入地址',
    ADDRESS_BLANK: '請輸入聯絡地址(路巷弄號樓)',
    CITY_PLACEHOLDER: '請選擇縣市',
    CITY_BLANK: '請輸入聯絡地址（縣市）',
    SITEID_PLACEHOLDER: '請選擇區域',
    SITEID_BLANK: '請輸入聯絡地址（區）',
    PHONE: '手機號碼:',
    PHONE_EX: '(範例) 0912345678',
    PHONE_PLACEHOLDER: '0912345678',
    PHONE_BLANK: '請輸入手機號碼',
    PHONE_INVALID: '請輸入正確的電話號碼',
    EMAIL: '電子信箱:',
    EMAIL_PLACEHOLDER: 'example@example.com',
    EMAIL_INVALID: '請輸入正確的電子信箱',
    PROGRESS: '{value0}/{value1} 頁',
    PLEASE_FILL_INFO: '請填寫您的發送資訊（請務必填寫正確，以利發送作業）',
    PLEASE_CHECK_INFO: '請確認以下資訊是否正確',
    CONFIRM_MSG: '如遇贈獎缺貨時三得利會以其他同等值贈獎代替。',
    GET_GIFT: 'このプレゼントは必ずもらえます',
    LOTTERY_GIFT: '本贈獎為抽獎活動',
    PRESENT: '發送內容',
    SHIPPING_INFO: '發送資訊',
    MODAL: {
      CONTENT: '恭喜您！\n您獲得了三得利準備的好禮！快來查看您的禮物吧！',
      LOTTERY_CONTENT: '恭喜您！\n您有機會獲得三得利準備的專屬好禮！快來參加抽獎吧！',
      RECEIVE_GIFT_BTN: '查看詳情領禮物!',
      RECEIVE_LOTTERY_GIFT_BTN: '點選查看詳情!',
      DATE: '贈獎申請期限至{value0}月{value1}日為止',
    },
  },
  DAYS: {
    JP: {
      Sunday: '星期日',
      Monday: '星期一',
      Tuesday: '星期二',
      Wednesday: '星期三',
      Thursday: '星期四',
      Friday: '星期五',
      Saturday: '星期六',
    },
    EN: {
      SUN: 'Sunday',
      MON: 'Monday',
      TUE: 'Tuesday',
      WED: 'Wednesday',
      THU: 'Thursday',
      FRI: 'Friday',
      SAT: 'Saturday',
    },
  },
  NOT_FOUND: {
    TITLE: '發生錯誤',
    SUB_TITLE: '請按下方「重新載入」按鈕再試一次',
    BTN_LABEL: '重新載入',
  },
  NOT_SESAMIN_USER: {
    DESCRIPTION: '如沒有完成專業檢測，將無法收到最適合的健康小任務與後續健康追蹤檢測。',
    BTN_LABEL: '立即開始全新版檢測',
  },
  USER_INFO_FORM: {
    phone: '訂購人電話號碼',
    phone_ex: '(範例) 0912345678',
    phone_placeholder: '請輸入訂購人電話號碼',
    phone_blank: '請輸入正確的電話號碼',
    email: '帳號或電子信箱',
    register_email: '電子信箱',
    id_email_placeholder: '請輸入帳號 或 Email',
    email_placeholder: '請輸入Email',
    EMAIL_INVALID: '請輸入正確的電子信箱',
    name: '訂購人姓名',
    name_placeholder: '請輸入真實姓名',
    name_blank: '請輸入訂購者的姓名',
    password: '請輸入密碼',
    password_placeholder: '請輸入密碼',
    password_blank: '請輸入正確的密碼',
    forgot_password: '忘記密碼',
    invalid_ec_acc: '登入失敗，請重新登入',
  },
  FOOTER: {
    TERMS: '使用規範',
    PRIVACY_POLICY: '隱私權與個資保護聲明',
    COPYRIGHT1: `&copy; Copyright, SUNTORY WELLNESS TAIWAN LIMITED.`,
    COPYRIGHT2: 'All rights reserved.',
  },
  QUESTIONNAIRE: {
    DAY_1: {
      PAGE_TITLE: '專業檢測​',
      CONTENT_1: '《三得利》為瞭解「睡眠」對您的生活重要性與造成的影響，希望藉由您的回饋及調查結果',
      CONTENT_2: '提供您一個身心健康、體質養成的生活建議',
      CONTENT_3:
        '，透過生活小習慣出發，重拾良好的睡眠品質，為現在的您創造新的幸福生活。\n想知道您是哪一種睡眠類型嗎？試試回答以下問題吧！\n選項說明：\n總是：每天\n經常：一週5-6次\n偶爾：一週3-4次\n很少：一週1-2次\n從不：一週皆未做過',
      CONTENT_4: '',
      CONTENT_5: '',
      QUESTIONS_INFO: '(問題數: {value0}題，約3~5分鐘)',
      START_BTN: '開始專業檢測',
      NOTE: '※溫馨提示：睡眠習慣的主觀感受很重要，測驗時不需細想確切時間，以自身感覺為準即可。\n※由良醫健康網指定專業醫師監修',
      TYPE_NEW: {
        CONTENT_1:
          '快來進行得利獅健康小檢測，瞭解您此刻的身心是屬於哪種狀態吧！不管是「深層修練果敢獅」還是「放鬆身心舒緩獅」，我們都會依據您的結果提供最適合的生活小任務，幫助您常保良好的身心狀態！',
        CONTENT_2: '*溫馨提示：健康的主觀感受很重要，測驗時不需細想確切時間，以自身感覺為準即可。',
      },
    },
    DAY_30: {
      PAGE_TITLE: '30天專業檢測​',
      CONTENT_1:
        '您好，謝謝您加入本次「天天集得利」，經過30天健康體質養成任務挑戰，透過三得利的陪伴，不知道您是否有感受到身體及生活有了不一樣的變化呢？\n透過以下的問題，來',
      CONTENT_2: '檢視自己的轉變',
      CONTENT_3: '，讓好的習慣繼續保持下去，',
      CONTENT_4: '達到身心健康的體質養成！\n',
      CONTENT_5:
        '問卷說明：\n針對以下問題，如對自己目前的狀態很滿意請選擇5分，若覺得目前仍不滿意自己的狀態則請選擇1分。',
      QUESTIONS_INFO: '(問題數: {value0}題，約3~5分鐘)',
      START_BTN: '開始30天專業檢測​',
      NOTE: '*溫馨提示：睡眠習慣的主觀感受很重要，測驗時不需細想確切時間，以自身感覺為準即可。\n*由良醫健康網指定專業醫師監修',
      TYPE_NEW: {
        CONTENT_1:
          '快來進行得利獅健康小檢測，瞭解您此刻的身心是屬於哪種狀態吧！不管是「深層修練果敢獅」還是「放鬆身心舒緩獅」，我們都會依據您的結果提供最適合的生活小任務，幫助您常保良好的身心狀態！',
        CONTENT_2: '*溫馨提示：健康的主觀感受很重要，測驗時不需細想確切時間，以自身感覺為準即可。',
      },
    },
    DAY_60: {
      PAGE_TITLE: '60天專業檢測​',
      CONTENT_1:
        '您好，謝謝您加入本次「天天集得利」，經過60天健康體質養成任務挑戰，透過三得利的陪伴，不知道您是否有感受到身體及生活有了不一樣的變化呢？\n透過以下的問題，來',
      CONTENT_2: '檢視自己的轉變',
      CONTENT_3: '，讓好的習慣繼續保持下去，',
      CONTENT_4: '達到身心健康的體質養成！\n',
      CONTENT_5:
        '問卷說明：\n針對以下問題，如對自己目前的狀態很滿意請選擇5分，若覺得目前仍不滿意自己的狀態則請選擇1分。',
      QUESTIONS_INFO: '(問題數: {value0}題，約3~5分鐘)',
      START_BTN: '開始60天專業檢測​',
      NOTE: '*溫馨提示：睡眠習慣的主觀感受很重要，測驗時不需細想確切時間，以自身感覺為準即可。\n*由良醫健康網指定專業醫師監修',
      TYPE_NEW: {
        CONTENT_1:
          '快來進行得利獅健康小檢測，瞭解您此刻的身心是屬於哪種狀態吧！不管是「深層修練果敢獅」還是「放鬆身心舒緩獅」，我們都會依據您的結果提供最適合的生活小任務，幫助您常保良好的身心狀態！',
        CONTENT_2: '*溫馨提示：健康的主觀感受很重要，測驗時不需細想確切時間，以自身感覺為準即可。',
      },
    },
    DAY_90: {
      PAGE_TITLE: '90天專業檢測​',
      CONTENT_1:
        '您好，謝謝您參加本次「天天集得利」，經過90天健康體質養成任務挑戰，透過三得利的陪伴，不知道您是否有感受到身體及生活有了不一樣的變化呢？\n透過以下的問題，來',
      CONTENT_2: '檢視自己的轉變',
      CONTENT_3: '，讓好的習慣繼續保持下去，',
      CONTENT_4: '達到身心健康的體質養成！\n',
      CONTENT_5:
        '問卷說明：\n針對以下問題，如對自己目前的狀態很滿意請選擇5分，若覺得目前仍不滿意自己的狀態則請選擇1分。',
      QUESTIONS_INFO: '(問題數: {value0}題，約3~5分鐘)',
      START_BTN: '開始90天專業檢測',
      NOTE: '*溫馨提示：睡眠習慣的主觀感受很重要，測驗時不需細想確切時間，以自身感覺為準即可。\n*由良醫健康網指定專業醫師監修',
      TYPE_NEW: {
        CONTENT_1:
          '快來進行得利獅健康小檢測，瞭解您此刻的身心是屬於哪種狀態吧！不管是「深層修練果敢獅」還是「放鬆身心舒緩獅」，我們都會依據您的結果提供最適合的生活小任務，幫助您常保良好的身心狀態！',
        CONTENT_2: '*溫馨提示：健康的主觀感受很重要，測驗時不需細想確切時間，以自身感覺為準即可。',
      },
    },
    PAGE_TITLE: {
      DAY_1: '專業檢測​',
      DAY_60: '60天專業檢測​',
      DAY_90: '90天專業檢測​',
    },
    PROGRESS: '{value0}/{value1} 頁',
    SUBMIT_ANSWER: '回答完畢，查看檢測結果',
    RETURN_PRE_QUESTION: '< 回上一題',
  },
  FEEDBACK: {
    START: {
      TITLE: '恭喜您完成30天的天天集得利體驗！',
      CONTENT: '請與我們分享您的感想！\n同時請選擇您想要的禮物，填寫正確的發送地址以方便我們寄送贈品給您！',
      START_BTN: '回答問卷',
    },
    THANK: {
      TITLE: '感謝您的填寫',
      SUB_TITLE: '今後也與三得利持續維持健康理想狀態吧！👍',
      CLOSE_BTN: '關閉',
      CONDITION: `・贈獎發送資格限加入活動對象商品之定期購且契約持續中的客戶，或是近六個月有單次購買活動對象商品的客戶。
        ・活動對象商品：
          芝麻明EX、蜂王乳＋芝麻明E、DHA＆EPA+芝麻明E、Liftage麗芙緹PG-EX、Milcolla蜜露珂娜、固力伸、TADAS 比菲禦力菌、極之青汁【大麥若葉+明日葉】、健鈣立、御瑪卡【精胺酸+鋅】、益思健、 BODYSAPO躍勁`,
      CONTENT: `・活動地區（同贈獎寄送地點）：僅限台灣地區。
      ・依據中華民國稅法規定，中獎價值在新台幣1,000元(含)以上者，贈與物品的價值將併入中獎人個人總合所得稅申報。三得利將寄送「領獎資料」予中獎人，請中獎人配合填寫並繳交身分證影本作為申報依據。
      ・本贈獎抽出後將以EMAIL、發送、電話或簡訊通知中獎者，不另外公布中獎名單。領獎期限為中獎通知後一個月內，如逾期未依主辦單位之規定提供必要資料者，視為放棄得獎資格，將不另行通知亦不予遞補。
      ・三得利健康網路商店有權審核參加者資格，若不符合活動規則所定資格，將不列入抽獎名單，恕不另行通知。
      ・中獎獎品不得折換現金。如遇產品缺貨或不可抗力之事由導致獎品內容變更，主辦單位有權變更贈獎，改由等值商品取代之，得獎者不得要求折現或轉換其他商品。
      ・獎項經兌換、簽收受領後之使用、保固與維修相關問題，請中獎者逕洽該獎項製造商，本公司不負瑕疵擔保責任。
      ・本活動將依個人資料保護法辦理。
      ・每獎項每人限得獎一次，恕不得重複得獎。`,
    },
    page_title: '天天集得利體驗版問卷',
    next_question: '完成！填寫下一題',
    placeholder: '請輸入您的回答',
    previous_question: '< 回上一題',
    other_ideas: '如有其他想法也歡迎與我們分享！',
    consignee_name: '收貨人姓名:',
    consignee_name_blank: '請輸入收貨人姓名',
    consignee_name_placeholder: '請輸入真實姓名',
    address: '收貨人地址:',
    address_placeholder: '請務必填寫完整地址以利寄送禮物',
    address_blank: '請輸入收貨人地址',
    progress: '{value0}/{value1} 頁',
  },
  MAINTENANCE: {
    PAGE_TITLE: '三得利《健康得利計畫》​\n平台服務整合通知​',
    CONTENT_START:
      '感謝您一直以來對三得利《健康得利計畫》的愛用與支持！為提供更完善、全面的服務，本帳號已停止更新，原服務將整合至',
    CONTENT_URL:
    '【三得利健康網路商店】LINE官方帳號',
    CONTENT_END:
    '，並更名為【天天集得利】，敬請加入官方帳號為好友並完成綁定即可繼續使用本服務。\n造成您的不便深感抱歉。讓三得利陪您持續養成健康體質，朝理想一步步前進！​',
  },
  RELEASE_MAINTENANCE: {
    PAGE_TITLE: '系統維護中​​',
    CONTENT: '目前系統進行維護作業中，請稍後再試。​造成您的不便，敬請見諒。​​',
  },
  RESULTS: {
    PAGE_TITLE: '初回診断と比べてみましょう！',
    CHART_MEMO: '初回診断と\n比較を行う\nレーダーチャート',
  },
  REMINDER_SETTING: {
    PAGE_TITLE: '通知時間設定​',
    GUIDE: '輕觸即可調整每日提醒時間!',
    NOTE: '*變更將於隔天生效。',
    DRINKING_TITLE: '保健商品食用時間​',
    ACTION_TITLE: '每日任務通知時間​',
    NOTICE_TITLE: '所有通知​',
    SUBMIT: '儲存並關閉',
    NOTIFICATION_OFF: '暫停每日任務提醒',
    NOTIFICATION_ON: '開啟每日任務提醒',
    NOTIFICATION_OFF_NOTE: '按下「暫停每日任務提醒」按鈕後就無法接收每天的任務並獲得每日任務勳章。',
    NOTIFICATION_ON_NOTE:
      '按下「開啟每日任務提醒」按鈕後，天天集得利會每天為您提供適合您的健康任務，持續達成任務還能獲得任務勳章!',
    NOTIFICATION_ALL_OFF: '暫停所有提醒',
    NOTIFICATION_ALL_ON: '開啟所有提醒',
    MODAL_CONFIRM: {
      TITLE_ON: '您確定要開啟每日任務提醒嗎?',
      NOTE_ON:
        '按下「開啟每日任務提醒」按鈕後，天天集得利會每天為您提供適合您的健康任務，持續達成任務還能獲得任務勳章!',
      BTN_ON: '開啟每日任務提醒',
      TITLE_OFF: '您確定要停止每日任務提醒嗎?',
      NOTE_OFF: '暫停每日任務提醒後連續達成任務勳章的紀錄將會自動歸零，請再次確認是否要停止每日任務提醒。',
      BTN_OFF: '暫停每日任務提醒',
      SUGGEST_ANSWER_QUESTION_TEXT: '如沒有完成專業檢測，將無法收到最適合的健康小任務與後續健康追蹤檢測。',
      SUGGEST_ANSWER_QUESTION_BTN: '開始專業檢測',
    },
    ALL_MODAL_CONFIRM: {
      TITLE_ON: '您確定要開啟所有提醒嗎?',
      BTN_ON: '開啟所有提醒',
      TITLE_OFF: '您確定要停止所有提醒嗎?',
      BTN_OFF: '暫停所有提醒',
      SUGGEST_ANSWER_QUESTION_TEXT: '如沒有完成專業檢測，將無法收到最適合的健康小任務與後續健康追蹤檢測。',
      SUGGEST_ANSWER_QUESTION_BTN: '開始專業檢測',
    },
    CHANGE: '變更',
    TITLE_CHANGED: '使用中商品',
    NOTIFICATION_SUGGEST_ANSWER: '*如您尚未完成專業檢測，按下「開啟每日任務提醒」按鈕後將先引導您進行檢測。',
  },
  RESULT_ANSWER: {
    MODAL: {
      TITLE: '專業檢測全面升級！',
      DESCRIPTION: '天天集得利推出全新的健康檢測囉！只要不到5分鐘就能了解自己的狀態，獲得客製的健康行動建議！',
      BTN_SUBMIT: '立即開始全新版檢測',
      BTN_CLOSE: '下次再檢測',
    },
    TITLE_RESULT: '您的{value0}天檢測結果為「{value1}」!',
    TITLE_RESULT_DAY1: '您的檢測結果為「{value0}」!',
  },
  LSWC: {
    POINT: '點',
    VERIFYQR: {
      ITEM: '您掃描的商品為',
      LEVEL: '您的等級是',
      GET: '即將獲得',
      POINTS: '得利金 +',
      BUY_FROM: '請選擇本商品之購買管道',
      NECESSARY: '必須',
      OFFICIAL: '三得利官方通路',
      OFFICIAL_EX: '官網/電視廣告/電話客服中心',
      OTHERS: '其他官方指定通路',
      OTHERS_EX: '實體門市/網路商城/電視購物頻道',
      NOTICE: '注意事項：',
      DESCRIBE: '',
      BTN_SUBMIT: '送出',
      BTN_CONTINUE: '繼續綁定',
    },
    GET_POINTS: {
      RESULT_SUCCESS: '會員綁定成功',
      RESULT_FAILED: '會員綁定未成功',
      DESCRIBE_SUCCCESS: '恭喜您完成綁定！您現在可以開始使用LINE個人化服務囉！',
      DESCRIBE_FAILED: '很抱歉，您的會員綁定未成功。請再次確認您輸入的資訊與留存會員資料是否相符。如您為新註冊之網路會員，系統將於1-2個工作天完成綁定，請耐心等候。',
      NOTICE: '*如您有任何疑問，請洽客服專線0800-320-686與我們聯繫，我們將竭誠為您服務。',
      BTN_RETRY: '重新綁定',
    },
    MODAL: {
      GET: '已獲得得利金',
      BTN_SUBMIT: '前往我的得利金',
    },
  },
};

export default TEXT;
