import React, { useEffect, useState } from 'react';
import TEXT from '@app/core/constants/text';
import Cookies from 'universal-cookie';

interface PropsInterface {
  closeModal: () => void;
}

const Error = (props: PropsInterface) => {
  const { closeModal } = props;
  const [qrStatus, setQrStatus] = useState<boolean>(false);
  const cookies = new Cookies({ path: '/LSWC',secure: true, sameSite:true, maxAge: 3600});
  const close = () => {
    cookies.remove('buyfrom');
    cookies.remove('itemStatus');
    cookies.remove('item');
    cookies.remove('code');
    cookies.remove('qrId');
    cookies.remove('points');
    cookies.remove('sccsId');
    closeModal();
  }

  useEffect(() => {
    setQrStatus(cookies.get('itemStatus') === 1);
  }, [])
  return (
    <>
      <div className="modal-body">{qrStatus? TEXT.API_ERROR.qr_code_failed : TEXT.API_ERROR.submit_failed}</div>
      <div className="modal-footer" onClick={close}>
        <span>{TEXT.GENERAL.CLOSE_WINDOW}</span>
      </div>
    </>
  );
};

export default Error;
